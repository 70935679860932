<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Invoice & Pembayaran</b> {{clinic_name}}
      </div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/payment/add')"
      >Tambah</b-button>
    </b-alert>

    <div class="row">

      <div class="col-12">
        <Card>
          <template v-slot:body>
            <Table
              :fields="fields"
              :items="items"
              :fieldPopUp="fieldPopUp"
              :itemPopUp="itemPopUp"
              :itemExcel="itemExcel"
              :hasPatient="false"
              :perPage="perPage"
              :currentPage="currentPage"
              :lastCurrentPage="lastCurrentPage"
              :totalRows="totalRows"
              :recordData="recordData"
              @pageOnClick="pageOnClick"
              @filter="generalFilter"
              @filterByPatient="generalFilter"
            />
          </template>
        </Card>
      </div>

    </div>
  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/payments/Table.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { getUser } from '@/core/services/jwt.service.js'

export default {

  components: {
    Card,
    Table
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: '',
        patient_name: '',
        start_date: '',
        end_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      recordData:0,
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Tagihan",
          sortable: true,
        },
        {
          key: "paid_amount_x_unpaid_amount",
          label: "Terbayar & Kekurangan",
        },
        {
          key: "payment_status_id",
          label: "Status",
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_created_at",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Tagihan",
          sortable: true,
        },
        {
          key: "paid_amount_x_unpaid_amount",
          label: "Terbayar & Kekurangan",
        },
        {
          key: "payment_status_id",
          label: "Status",
        },
      ],
      // Table Items
      items: [],
      itemPopUp: [],
      itemExcel:[],
      // access management
      manipulateBtn: false,
      // current user
      currentUser: getUser(),
      clinic_name : window.configuration == null ? "Medhop" :  window.configuration.clinic_name
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.lastCurrentPage = page
      this.pagination()
    },

    async pagination() {
      let route
      if (this.currentUser.user_type_id == 1) {
        route = `payments/get-by-doctor/${this.currentUser.user_id}`
      } else {
        route = 'payments'
      }
      let filterParams = `&patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(route, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      this.recordData = pagination.total;
      let a, b
      
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }
      this.items = response.data
      
      // //  export
      // let filterParamNoPaging = `?patient_name=${this.filter.patient_name}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      // let responseExcel = await module.get("payment-excel",`${filterParamNoPaging}`);
      // let responseNoPagination = await module.get("payment-no-paginations",`${filterParamNoPaging}`);
      // this.itemExcel = responseExcel
      // this.itemPopUp = responseNoPagination

      // console.log(this.itemExcel,this.itemPopUp,"dumpppppp");

    },

    generalFilter(sended) {
      this.filter.doctor_id = sended.doctor_id
      this.filter.start_date = sended.start_date
      this.filter.end_date = sended.end_date
      this.filter.patient_name = sended.patient_name

      if (this.filter.patient_name != "" || this.filter.doctor_id != '' || this.filter.start_date != '' || this.filter.end_date != '') {
        this.currentPage = 1;
      } else {
        this.currentPage = this.lastCurrentPage;
      }
      this.pagination()
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete('payments/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        
        if (access_right[a] == "6002") {
          this.manipulateBtn = true
        }
      }
    },

    async setPaymentToday() {
      this.filter.start_date = await new Date().toISOString().substr(0, 10)
      this.filter.end_date = await new Date().toISOString().substr(0, 10)
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sales", route: "" },
      { title: "Invoice & Pembayaran" },
    ])
    this.pagination()
    this.setActiveMenu()
  },

}
</script>